<template>
  <v-app>
      <v-app-bar app color="primary">
        <v-toolbar-title>
          Beaker Soft
        </v-toolbar-title>
      </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    //
  }),
};
</script>
